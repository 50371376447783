<template>
    <div class="p-grid">
        <div style="margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff"
             :style="expandSidebarDialog ? 'width:70rem;' : 'width:20rem;'"
        >
        <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
          <h3 class="p-m-0" style="line-height: .9">
            <Button
                v-tooltip="expandSidebarDialog ? _t('label_List_view') : _t('label_Table_view')"
                class="p-button-rounded p-button-icon-only p-button-text"
                :icon="expandSidebarDialog ? 'mdi mdi-table-arrow-left' : 'mdi mdi-table-arrow-right'"
                style="float: right;"
                @click="expandSidebarDialog = !expandSidebarDialog"
            />
        <Button
            v-tooltip.bottom="_t('label_create')"
            class="p-button-rounded p-button-success p-button-text"
            icon="pi pi-plus"
            style="float: right;"
            @click="initNewCalendarDialog()"
        />
        <strong>{{ _t('label_ap_rotationals') }}</strong>
        <br>
        <span style="margin-left: 5px; font-size: 12px;">{{ _t('label_prev_Rota_calendar') }}</span>
        </h3>

        <br>
        <MultiSelectableBreadcrumb
            ref="breadcrumbData"
            :tree-params="treeParams"
            single-selection
            @change="setOrgId($event)"
        />
            </div>
        <div>
        <span class="p-input-icon-right p-m-3" :style="expandSidebarDialog ? 'width:' + ' calc(100% - 3rem)' : 'width:' + ' calc(100% - 3rem);'">
          <i class="pi pi-search" style="font-size: 12px;"/>
          <InputText v-model="filterValue" style="width:100%;" type="text"/>
        </span>
        </div>
            <DataTable
                ref="tree"
                v-model:selection="selectedCalendarItem"
                :dataKey="tableDataKey"
                :lazy="true"
                :value="getCalendars"
                :page="currentPage"
                :totalRecords="getCalendarMeta.count"
                class="tight"
                scrollHeight="75vh"
                selectionMode="single"
                :paginator="true"
                :rows="tableRows"
                :rowHover="true"
                :scrollable="true"
                stripedRows
                :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
                @page="selectTablePage($event)"
                @row-click="changeCalendar($event.data.id)"
            >
            <Column field="name"
                    :header="_t('Name')"
                    :style="'width: ' + (100 / standbySchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
            >
                <template #body="{data}">
              <span
                      class="wrap"
                      style="word-break: break-all; padding-left: 5px;"
              >
              {{ data.surname }} {{ data.name }}
              </span>
                </template>
            </Column>

            <Column v-if="expandSidebarDialog"
                    field="description"
                    :hidden="!handleCheckRow('description')"
                    :header="_t('label_description')"
                    :style="'width: ' + (100 / standbySchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
            >
                <template #body="{data}">
              <span
                      style="word-break: break-all; padding-left: 5px;"
              >
                {{ data.description }}
              </span>
                </template>
            </Column>

            <Column v-if="expandSidebarDialog"
                    :header="_t('label_org_name')"
                    :hidden="!handleCheckRow('orgName')"
                    :style="'width: ' + (100 / standbySchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
                    field="orgName">
                <template #body="{data}">
              <span
                      style="word-break: break-all; padding-left: 5px;"
              >
                {{ data.orgName }}
              </span>
                </template>
            </Column>

            <Column v-if="expandSidebarDialog"
                    field="validFrom"
                    :hidden="!handleCheckRow('validFrom')"
                    :header="_t('label_valid_from')"
                    :style="'width: ' + (100 / standbySchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
            >
                <template #body="{data}">
              <span
                      style="word-break: break-all; padding-left: 5px;"
              >
                {{ data.validFrom }}
              </span>
                </template>
            </Column>

            <Column v-if="expandSidebarDialog"
                    field="Rotation"
                    :header="_t('label_rotation')"
                    :hidden="!handleCheckRow('rotation')"
                    :style="'width: ' + (100 / standbySchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
            >
                <template #body="{data}">
              <span
                      style="word-break: break-all; padding-left: 5px;"
              >
                {{ dateTime(data.validFrom) }}
              </span>
                </template>
            </Column>

            <Column v-if="expandSidebarDialog"
                    field="assignment"
                    :header="_t('label_assignment')"
                    :hidden="!handleCheckRow('assignment')"
                    :style="'width: ' + (100 / standbySchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
            >
                <template #body="{data}">
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                      <span v-for="(member, idx) in data.membership" :key="member.id"
                            style="word-break: break-all; padding-left: 5px;"
                      >
                {{ member.name }}{{idx !== data?.membership?.length - 1 ? ';' : ''}}
              </span>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <transition name="slide-fade">
      <div class="p-col">
        <Card v-if="getCalendar" class="p-mt-1 p-ml-0">
          <template #content>
            <div v-if="getCalendar">
              <div class="p-d-flex p-jc-between">
                <div><h3 class="p-m-0"><strong>{{ getCalendar.name }}</strong></h3></div>
                <div>
                  <slot name="controls">
                    <Button v-if="getIsEditable" v-tooltip.bottom="_t('label_save')"
                            class="p-mr-2 p-button-success p-button-rounded p-button-text"
                            icon="pi pi-save"
                            @click="saveCalendar"
                    />
                    <Button v-if="!getIsEditable" v-tooltip.bottom="_t('Edit')"
                            class="p-mr-2 p-button-success p-button-rounded p-button-text"
                            icon="pi pi-pencil"
                            @click="setIsEditable(true)"/>
                    <Button v-if="getCalendar.id" v-tooltip.bottom="_t('Clone')"
                            class="p-mr-2 p-button-success p-button-rounded p-button-text"
                            icon="pi pi-clone"
                            @click="cloneCalendarAction"/>
                    <Button v-tooltip.bottom="_t('Delete')" class="p-button-danger p-button-rounded p-button-text"
                            icon="pi pi-trash"
                            @click="removeSelectedCalendar"/>
                    <Button v-if="getIsEditable" v-tooltip.bottom="_t('Cancel')"
                            class="p-button-danger p-button-rounded p-button-text"
                            icon="pi pi-times" @click="cancelCalendar"/>
                  </slot>
                </div>
              </div>
              <TabView :disabled="!getCalendar">
                <TabPanel
                    :disabled="!getCalendar"
                    :header="_t('menu.general')"
                >
                  <CalendarConfigurator
                      v-if="getCalendar"
                      ref="configurator"
                      :orgId="orgId"
                      @clone="cloneCalendar"
                  />
                </TabPanel>
                <TabPanel
                    :disabled="!getCalendar"
                    :header="_t('label_alarmpoints')"
                >
                  <ul v-if="getAssignment && getAssignment.length">
                    <h6><strong>{{ _t('label_schedule_assigned_to') }}:</strong></h6>
                    <li
                        v-for="assignment in getAssignment"
                        :key="assignment.id"
                    >
                      <router-link :to="'/alarm-points/'+assignment.id">
                        {{ assignment.name }}
                      </router-link>
                    </li>
                  </ul>
                  <div v-else>{{ _t('label_no_alarms_found') }}</div>
                </TabPanel>
              </TabView>
            </div>
          </template>
        </Card>
      </div>
    </transition>
  </div>
</template>

<script>
import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb';
import CalendarConfigurator from './calendar-configurator';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {cloneCalendar} from "@/api/alarm-rotational";
import {getOrganizationColumns, getUserColumns} from "@/api/uiColumns";
import moment from "moment";

export default {
  components: {MultiSelectableBreadcrumb, CalendarConfigurator},
  data: () => ({
    treeParams: null,
    selectedCalendarItem: null,
    orgId: localStorage.getItem('orgId') ?? 1,
    weekdays: [
      'label_sunday_short',
      'label_monday_short',
      'label_tuesday_short',
      'label_wednesday_short',
      'label_thursday_short',
      'label_friday_short',
      'label_saturday_short',
    ],
    checkAccessIntervalId: null,
    tableRows: 100,
    currentPage: 0,
    filterValue: '',
    expandSidebarDialog: false,
    standbySchedulesValues: [],
  }),
  mounted() {
    this.callCalendarsAction();
    this.checkAccess()

    getUserColumns().then((userRes) => {
        if (userRes.data) {
            for (const [key, value] of Object.entries(userRes.data)) {
                if (key === 'standbySchedulesItems') this.standbySchedulesValues = value
            }
        } else {
            getOrganizationColumns().then((res) => {
                if (res.data) {
                    for (const [key, value] of Object.entries(res.data)) {
                        if (key === 'standbySchedulesItems') this.standbySchedulesValues = value
                    }
                }
            }).catch((err) => console.log('error', err))
        }
    }).catch((err) => console.log('error', err))
  },
  beforeUnmount() {
    clearInterval(parseInt(this.checkAccessIntervalId))
  },
  methods: {
    ...mapActions({
      'callCalendars': 'rotationalCalendar/callCalendars',
      'callAlarmPlans': 'rotationalCalendar/callAlarmPlans',
      'callEvents': 'rotationalCalendar/callEvents',
      'callRemoveCalendar': 'rotationalCalendar/callRemoveCalendar',
      'callCreateCalendar': 'rotationalCalendar/callCreateCalendar',
      'callAssignment': 'rotationalCalendar/callAssignment',
    }),
    ...mapMutations({
      'selectCalendar': 'rotationalCalendar/selectCalendar',
      'createEmptyCalendar': 'rotationalCalendar/createEmptyCalendar',
      'setCalendar': 'rotationalCalendar/setCalendar',
      'setIsEditable': 'rotationalCalendar/setIsEditable',
      'setEvents': 'rotationalCalendar/setEvents',
    }),
      dateTime(value) {
          return moment(value).format('dddd');
      },
    selectTablePage(item) {
      this.loading = true
      this.currentPage = item.page
      this.callCalendarsAction();
    },
    checkAccess() {
      this.checkAccessIntervalId = setInterval(() => {
        if (localStorage.getItem('accessDeniedReason')) {
          this.$root.showMessage(localStorage.getItem('accessDeniedReason'), 'error')
          localStorage.removeItem('accessDeniedReason')
        }
      }, 1000);
    },
    setOrgId(orgId) {
      this.orgId = orgId;
      this.callCalendarsAction();
      this.callAlarmPlans(this.orgId);
      if (this.getCalendar && this.getCalendar.id === null) {
        this.setCalendar({
          ...this.getCalendar,
          orgId: this.orgId,
          orgName: this.$refs.breadcrumbData?.breadcrumbs?.[0]?.data?.[0]?.label,
        });
      } else if (this.getCalendar && this.getCalendar.id !== null) {
        this.selectCalendar(null);
        this.selectedCalendarItem = null;
      }
    },
    changeCalendar(id) {
      this.selectCalendar(id);
      if (id) {
        this.callAssignment(id);
        this.callEvents(id);
      }
    },
    initNewCalendarDialog() {
      const orgName = this.$refs.breadcrumbData?.breadcrumbs?.[0]?.data?.[0]?.label;
      this.createEmptyCalendar({orgId: this.orgId, orgName});
      this.selectedCalendarItem = null;
      this.setIsEditable(true);
    },
    cloneCalendarAction() {
      const calendar = {...this.getCalendar};
      calendar.alarmPointWeekGroups = [...this.getEvents]
      cloneCalendar(calendar).then((response) => {
        this.setCalendar(response.data);
        this.setIsEditable(true);
        this.callCalendarsAction()
      })
    },
    removeSelectedCalendar() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Delete_schedule'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.callRemoveCalendar()
              .then(
                  () => {
                  },
                  error => {
                    console.log(error);
                    this.$root.showMessage(error.reason ?? error, 'error')
                    return false;
                  }
              );
          this.$refs.configurator.validateWeeks();
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    cancelCalendar() {
      if (!this.getCalendar.id) {
        this.callRemoveCalendar()
      }
      this.callCalendars({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        tableRows: this.tableRows
      }).then(() => {
            this.changeCalendar(this.selectedCalendarItem?.id);
      })
      this.setIsEditable(false)
    },
    saveCalendar() {
      this.$refs.configurator.createUpdateNewCalendar();
      this.callCalendarsAction();
    },
    callCalendarsAction() {
      this.callCalendars({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        tableRows: this.tableRows
      }).then(() => {
        this.changeCalendar(this.selectedCalendarItem?.id);
      });
    },
    handleCheckRow(name) {
        const foundedValue = this.standbySchedulesValues.find(({value}) => value === name)
        return !!foundedValue?.checked?.length;
    },
  },
  computed: {
    ...mapGetters({
      'getCalendars': 'rotationalCalendar/getCalendars',
      'getCalendarMeta': 'rotationalCalendar/getCalendarMeta',
      'getCalendar': 'rotationalCalendar/getCalendar',
      'getEvents': 'rotationalCalendar/getEvents',
      'getIsEditable': 'rotationalCalendar/getIsEditable',
      'getAssignment': 'rotationalCalendar/getAssignment',
    }),
  },
  watch: {
    getCalendar: function () {
      this.selectedCalendarItem = this.getCalendar;
    },
    filterValue() {
      this.callCalendarsAction();
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
